import { useScroll } from 'framer-motion';
import { useState, useEffect } from 'react';
export default function useOffSetTop(top, options) {
    if (top === void 0) { top = 100; }
    var scrollY = useScroll(options).scrollY;
    var _a = useState(false), value = _a[0], setValue = _a[1];
    useEffect(function () {
        return scrollY.on('change', function (scrollHeight) {
            if (scrollHeight > top) {
                setValue(true);
            }
            else {
                setValue(false);
            }
        });
    }, [scrollY, top]);
    return value;
}
// Usage
// const offset = useOffSetTop(100);
// Or
// const offset = useOffSetTop(100, {
//   container: ref,
// });
