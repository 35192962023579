var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
// @mui
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
export default function useResponsive(query, start, end) {
    var theme = useTheme();
    var mediaUp = useMediaQuery(theme.breakpoints.up(start));
    var mediaDown = useMediaQuery(theme.breakpoints.down(start));
    var mediaBetween = useMediaQuery(theme.breakpoints.between(start, end));
    var mediaOnly = useMediaQuery(theme.breakpoints.only(start));
    if (query === 'up') {
        return mediaUp;
    }
    if (query === 'down') {
        return mediaDown;
    }
    if (query === 'between') {
        return mediaBetween;
    }
    return mediaOnly;
}
export function useWidth() {
    var theme = useTheme();
    var keys = __spreadArray([], theme.breakpoints.keys, true).reverse();
    return (keys.reduce(function (output, key) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        var matches = useMediaQuery(theme.breakpoints.up(key));
        return !output && matches ? key : output;
    }, null) || 'xs');
}
